import React, { useState } from 'react'

import Menu from './Menu'

export default function Header({ pagination = null }) {
  const [state, setState] = useState(null)

  return (
    <>
      <header className='absolute inset-x-0 leading-tight lowercase py-10 top-0 z-30 lg:fixed'>
        <div className='container-lg'>
          <div className='flex items-center justify-between -mx-20'>
            <div className='px-20'>
              <a
                className='text-24 lg:text-28 hover:underline focus:underline'
                href='/'
              >
                Justin<span className='hidden sm:inline'> Vandenberg</span>
              </a>
            </div>
            <div className='px-20'>
              <div className='flex items-center justify-between -mx-10'>
                {pagination && (
                  <div className='px-10'>
                    <div className='inline-flex items-center'>
                      <div className='px-10'>
                        <a
                          className='text-24 lg:text-28 hover:underline focus:underline'
                          href={`/projects/${pagination.prev.slug}`}
                        >
                          Prev
                        </a>
                      </div>
                      <div className='px-10'>
                        <a
                          className='text-24 lg:text-28 hover:underline focus:underline'
                          href={`/projects/${pagination.next.slug}`}
                        >
                          Next
                        </a>
                      </div>
                    </div>
                  </div>
                )}
                <div className='px-10'>
                  <button
                    className='menu mt-3'
                    onClick={() => {
                      setState('enter')
                    }}
                  >
                    <span className='sr-only'>Enter menu</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
      <Menu state={state} setState={setState.bind(this)} />
    </>
  )
}
