import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import { Helmet } from 'react-helmet'

export default function Seo() {
  const url = typeof window !== 'undefined' ? window.location.href : '/'

  return (
    <StaticQuery
      query={graphql`
        {
          seo: allMarkdownRemark(
            filter: { fileAbsolutePath: { regex: "/seo.md/" } }
          ) {
            edges {
              node {
                frontmatter {
                  title
                  metaDescription
                  facebook {
                    appId
                    title
                    description
                    image {
                      childImageSharp {
                        fixed(width: 1200) {
                          src
                        }
                      }
                    }
                    siteName
                  }
                  twitter {
                    handle
                    title
                    description
                    image {
                      childImageSharp {
                        fixed(width: 1200) {
                          src
                        }
                      }
                    }
                  }
                  home
                  canonical
                }
              }
            }
          }
        }
      `}
      render={(data) => {
        const seo = data.seo.edges[0].node.frontmatter

        return (
          <Helmet
            htmlAttributes={{
              lang: 'en',
            }}
          >
            <title>{seo.title}</title>
            <meta name='description' content={seo.metaDescription} />

            <meta property='fb:app_id' content={seo.facebook.appId} />
            <meta property='og:url' content={url} />
            <meta property='og:type' content='website' />
            <meta property='og:title' content={seo.facebook.title} />
            <meta
              property='og:description'
              content={seo.facebook.description}
            />
            <meta
              property='og:image'
              content={seo.facebook.image.childImageSharp.fixed.src}
            />
            <meta property='og:image:width' content='1200' />
            <meta property='og:image:height' content='630' />
            <meta property='og:site_name' content={seo.facebook.siteName} />

            <meta name='twitter:card' content='summary_large_image' />
            <meta name='twitter:site' content={seo.twitter.handle} />
            <meta name='twitter:url' content={url} />
            <meta name='twitter:title' content={seo.twitter.title} />
            <meta
              name='twitter:description'
              content={seo.twitter.description}
            />
            <meta name='twitter:image' content={seo.twitter.image} />

            <link rel='home' href={seo.home} />
            <link rel='canonical' href={seo.canonical} />
          </Helmet>
        )
      }}
    />
  )
}
