import React, { useEffect } from 'react'

export default function Menu({ state, setState }) {
  const duration = 500 // Must match value in tailwind.config.js
  const classes = {
    enter: 'translate-y-40 opacity-0',
    entered: 'opacity-100',
    exit: 'translate-y-40 opacity-0',
    exited: 'translate-y-40 opacity-0',
  }

  useEffect(() => {
    if (state === 'enter') setState('entered')

    let timer = null

    if (state === 'exit') {
      timer = setTimeout(() => {
        setState('exited')
      }, duration)
    }

    return () => {
      clearTimeout(timer)
    }
  }, [state, setState])

  if (!state || state === 'exited') return null

  return (
    <div
      className={`bg-white duration-${duration} fixed inset-0 max-h-screen overflow-y-auto transform z-40 ${classes[state]}`}
    >
      <div className='flex flex-col justify-center leading-tight lowercase'>
        <div className='container'>
          <ul className='py-100'>
            <li className='py-20'>
              <a
                href='/'
                className='text-40 md:text-48 lg:text-56 hover:underline focus:underline'
              >
                Home
              </a>
            </li>
            <li className='py-20'>
              <a
                href='/resume.pdf'
                className='text-40 md:text-48 lg:text-56 hover:underline focus:underline'
              >
                Resume
              </a>
            </li>
            <li className='py-20'>
              <a
                href='mailto:justinjvandenberg@gmail.com'
                className='text-40 md:text-48 lg:text-56 hover:underline focus:underline'
              >
                Contact
              </a>
            </li>
            <li className='py-20'>
              <div className='inline-flex items-center -mx-10 mt-20'>
                <div className='px-10'>
                  <a
                    href='https://github.com/justinvandenberg'
                    className='text-20 md:text-22 lg:text-24 hover:underline focus:underline'
                  >
                    GitHub
                  </a>
                </div>
                <div className='px-10'>
                  <a
                    href='https://www.linkedin.com/in/justin-vandenberg-22221a6b/'
                    className='text-20 md:text-22 lg:text-24 hover:underline focus:underline'
                  >
                    LinkedIn
                  </a>
                </div>
              </div>
            </li>
          </ul>
        </div>
        <div className='absolute mr-20 mt-10 right-0 top-0 lg:mr-40'>
          <button
            className='menu menu-entered mt-3'
            onClick={() => {
              setState('exit')
            }}
          >
            <span className='sr-only'>Exit menu</span>
          </button>
        </div>
      </div>
    </div>
  )
}
