import React, { useEffect, useState } from 'react'

import '../css/components/layout.css'

export default function Layout({ children }) {
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false)
    }, 500)

    return () => {
      clearTimeout(timer)
    }
  })

  return (
    <div className='layou duration-500' style={{ opacity: loading ? 0 : 1 }}>
      {children}
    </div>
  )
}
